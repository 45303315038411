import React from "react";
import { messagera } from "../../../../assets";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Header = () => {
  const history = useHistory();
  return (
    <div
      className="header-container"
      onClick={() => history.push("/messaging")}
    >
      <div className="logo-title-wrapper">
        <LazyLoadImage src={messagera} alt="taki-chat-logo" id="messagesLogo" />
        {/* <span>messaggera</span> */}
      </div>
    </div>
  );
};

export default Header;
